var $class="se2--header-se--common-part",$name="HeaderSE/commonPart",$path="app/components/HeaderSE/partials/commonPart/index.js",$this={$class,$name,$path,};import trimEnd from 'lodash/trimEnd';
import { headerClassName } from 'app/components/HeaderSE/config';

// eslint-disable-next-line max-len
import redefineTargetAttributeOfSustainabilityLink from 'app/components/utilities/redefineTargetAttributeOfSustainabilityLink';

import shell from 'app/modules/shell';

import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import isUsingKeyboard from 'app/utilities/isUsingKeyboard';

import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import { css } from 'configs';

import first from 'lodash/first';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import last from 'lodash/last';
import map from 'lodash/map';
import getCookie from 'app/utilities/getCookie';
import ajax from 'app/modules/ajax';
import { menuNavButtonSelector,
  closeMobileMenuButtonSelector,
  globalSearchAppId,
  openMobileMenuButtonSelector,
  sustainabilityLinkClassName } from './config';

const expandedMenuClassName = 'expanded-menu';

const headerHeightMobileCSSVariable = '--header-height-mobile';
const menuWrapSelector = '.mm-wrap';
const menuSectionSelector = '.mm-tab';
const menuL1ItemsSelector = '.mm-l1-item';
const seeAllL1LinksSelector = '.mm-cta-wrap';
const menuL1ButtonClassName = 'mm-l1-button-popup';
const menuL2ButtonSelector = '.mm-l2-button';
const menuL2ListSelector = '.mm-l2-list';
const menuL2ItemsSelector = '.mm-l2-item';
const menuL3ItemsSelector = '.mm-l3-list';
const menuWrapperSimplifiedSelector = '.menu-wrapper-simplified';
const mobileHeaderHeightWithGlobalSearch = 60;
const mobileMetabarSelector = '.mobile-metabar';
const promoCtaSelector = '.mm-promo-cta';
const b2bB2cToggleMobileSelector = '.mob-b2bB2cToggle';
const loaderClassName = 'se2-icon-loader';
const logoSELinkSelector = '.logo-se-link';
const mobileBlockWrapSelector = '.mobile-block-wrap';
const nestedCountrySelectorSelector = '.stubbed-content';
const startChinaLink = 'https://www.schneider-electric.cn/zh/';

let headerElement = null;
let menuWrapElement = null;
let closeMobileMenuButtonElement = null;
let openMobileMenuButtonElement = null;
let menuL1ItemsElements = null;
let mobileMetabarElement = null;
let b2bB2cToggleMobile = null;
let sustainabilityLink = null;
const getNodes = (headerCommonPartElement) => {
  headerElement = document.querySelector(`.${headerClassName}`);
  menuWrapElement = headerCommonPartElement.querySelector(menuWrapSelector);
  closeMobileMenuButtonElement = headerCommonPartElement.querySelector(closeMobileMenuButtonSelector);
  openMobileMenuButtonElement = headerCommonPartElement.querySelector(openMobileMenuButtonSelector);
  menuL1ItemsElements = headerCommonPartElement.querySelectorAll(menuL1ItemsSelector);
  mobileMetabarElement = headerCommonPartElement.querySelector(mobileMetabarSelector);
  b2bB2cToggleMobile = document.querySelector(b2bB2cToggleMobileSelector);
  sustainabilityLink = headerCommonPartElement.querySelector(`.${sustainabilityLinkClassName}`);
};

export default shell.registerPartial($this, ({
  addEventListener, configs, mount, log,
}) => {
  const closeMenu = ({ target, forcedClose, code }) => {
    if (!target.closest(menuWrapSelector) || forcedClose || code === configs.keyNames.escape) {
      // eslint-disable-next-line no-use-before-define
      forEach(
        menuL1ItemsElements,
        (element) => {
          element.classList.remove(css.classNames.close);
          element.classList.remove(css.classNames.open);
          element.querySelector(menuSectionSelector)?.classList.add(css.classNames.close);
        },
      );
      menuWrapElement.classList.remove(css.classNames.open);
      headerElement.classList.remove(expandedMenuClassName);
      mobileMetabarElement.classList.remove(configs.css.classNames.hide);
      b2bB2cToggleMobile?.classList.remove(configs.css.classNames.hide);

      if (isMobileBreakpoint() && (
        target.classList.contains(menuL1ButtonClassName)
        || target.closest(mobileMetabarSelector)
        || target.closest(b2bB2cToggleMobileSelector))
      ) {
        clearAllBodyScrollLocks();
        menuWrapElement.classList.add(css.classNames.close);
        headerElement.classList.remove(expandedMenuClassName);
        openMobileMenuButtonElement.focus();
      }

      window.removeEventListener('click', closeMenu);
      const menuL1Item = target.closest(menuL1ItemsSelector);
      if (isUsingKeyboard()) {
        menuL1Item?.querySelector(`.${menuL1ButtonClassName}`).setAttribute('tabIndex', '0');
      }
      if (code === configs.keyNames) {
        menuL1Item?.querySelector(`.${menuL1ButtonClassName}`).focus();
      }

      menuL1Item?.querySelector(`.${menuL1ButtonClassName}`).setAttribute('aria-expanded', false);
    }
  };

  const onMenuSectionKeyDown = (e) => {
    const mobileLinks = !e.target.closest(menuL3ItemsSelector)
      ? [...e.currentTarget.querySelectorAll(`${seeAllL1LinksSelector} a`),
        ...e.currentTarget.querySelectorAll(`${menuL2ButtonSelector}, ${menuL2ItemsSelector} > a`)]
      : [...e.target.closest(menuL3ItemsSelector).children];
    const links = !isMobileBreakpoint()
      ? [...e.currentTarget.querySelectorAll('a:not(.extra-links-item)')]
      : mobileLinks;
    const buttons = !isMobileBreakpoint()
      ? [...e.currentTarget.querySelectorAll(menuNavButtonSelector)]
      : [...e.currentTarget.querySelectorAll(menuNavButtonSelector),
        ...mobileMetabarElement.children].filter((button) => !button.classList.contains(css.classNames.hide));

    const currentButtonIndex = buttons.indexOf(e.target);
    const currentLink = e.target;
    const currentLinkIndex = links.indexOf(e.target);

    const [firstButton, firstLink] = [first(buttons), first(links)];
    const [nextButton, nextLink] = [buttons[currentButtonIndex + 1], links[currentLinkIndex + 1]];
    const [previousButton, previousLink] = [buttons[currentButtonIndex - 1], links[currentLinkIndex - 1]];
    const [lastButton, lastLink] = [last(buttons), last(links)];

    if (e.shiftKey && e.code === configs.keyNames.tab && firstLink === currentLink) {
      if (!isMobileBreakpoint()) {
        e.preventDefault();
        lastLink.focus();
      }
    }
    if (!e.shiftKey && e.code === configs.keyNames.tab && lastLink === currentLink) {
      if (!isMobileBreakpoint()) {
        e.preventDefault();
        firstLink.focus();
      }
    }
    if (e.key === configs.keyNames.home) {
      if (!isMobileBreakpoint()) {
        firstLink.focus();
      }
      e.preventDefault();
    }
    if (e.key === configs.keyNames.end) {
      if (!isMobileBreakpoint()) {
        lastLink.focus();
      }
      e.preventDefault();
    }

    if (e.key === configs.keyNames.arrowUp || (e.code === configs.keyNames.arrowLeft && isMobileBreakpoint())) {
      (previousLink ?? lastLink).focus();
      e.preventDefault();
    }

    if (e.key === configs.keyNames.arrowDown || (e.code === configs.keyNames.arrowRight && isMobileBreakpoint())) {
      (nextLink ?? firstLink).focus();
      e.preventDefault();
    }

    if (e.code === configs.keyNames.arrowLeft && !isMobileBreakpoint()) {
      (previousButton ?? lastButton)?.focus();
    }

    if (e.code === configs.keyNames.arrowRight && !isMobileBreakpoint()) {
      (nextButton ?? firstButton)?.focus();
    }
  };

  const onMenuButtonKeyDown = (e) => {
    const buttons = !isMobileBreakpoint()
      ? [...e.currentTarget.querySelectorAll(`.${menuL1ButtonClassName}`)]
      : filter(
        [
          ...e.currentTarget.querySelectorAll(`.${menuL1ButtonClassName}`),
          ...mobileMetabarElement.children,
        ],
        ({ classList }) => !classList.contains(css.classNames.hide),
      );
    const currentButtonIndex = buttons.indexOf(e.target);
    const firstButton = buttons[0];
    const lastButton = last(buttons);
    const nextButton = buttons[currentButtonIndex + 1];
    const previousButton = buttons[currentButtonIndex - 1];

    if (e.key === configs.keyNames.arrowRight || (e.code === configs.keyNames.arrowDown && isMobileBreakpoint())) {
      e.preventDefault();
      nextButton && nextButton.focus();
      !nextButton && firstButton.focus();
    }

    if (e.key === configs.keyNames.arrowLeft || (e.code === configs.keyNames.arrowUp && isMobileBreakpoint())) {
      e.preventDefault();
      previousButton && previousButton.focus();
      !previousButton && lastButton.focus();
    }
  };

  const focusFirstLink = (menuItem) => {
    const menuSection = menuItem.querySelector(menuSectionSelector);
    menuSection.querySelector('a').focus();
  };

  const disableArrows = (e) => {
    if ((e.code === configs.keyNames.arrowUp) || (e.code === configs.keyNames.arrowDown)) {
      e.preventDefault();
    }
  };

  const onEscCloseMenu = (e) => {
    if (e.key === configs.keyNames.escape) {
      closeMenu(e);
    }
  };

  const userType = getCookie(configs.sso.userTypeCookieName);

  const filterMenuByUserType = (menuElement) => {
    log.info('Filtering product menu by user type: %s', userType);
    forEach(
      menuElement
        .querySelectorAll(`[data-profession-codes]:not([data-profession-codes*=${userType}])`),
      (link) => link.parentNode.removeChild(link),
    );
  };

  const initializeMenuItem = (menuItem) => {
    const productsMenuSelector = '.mm-main-list-products';

    const productsMenu = menuItem.querySelector(productsMenuSelector);
    if (productsMenu && userType && userType !== configs.sso.nonAuthorizedUserType) {
      filterMenuByUserType(productsMenu);
    }
  };

  const showMenuL1ItemOnClick = (menuItem, showLoader) => {
    const placeholderElement = menuItem.querySelector(nestedCountrySelectorSelector);
    if (!placeholderElement) return Promise.resolve();

    placeholderElement.classList.toggle(loaderClassName, showLoader);
    const { path } = placeholderElement.dataset;
    return ajax.fetchDeferralTo(path, placeholderElement)
      .then(() => placeholderElement.classList.remove(loaderClassName));
  };

  const loadDeferredMenuItems = async (menuItem, showLoader) => {
    if (menuItem.dataset.initialized) return;
    menuItem.dataset.initialized = true;

    try {
      await showMenuL1ItemOnClick(menuItem, showLoader);
      initializeMenuItem(menuItem);
    } catch {
      menuItem.dataset.initialized = false;
    }
  };

  const toggleL1Items = async (menuItem = null) => {
    // close l1 menu
    if (menuItem?.classList.contains(css.classNames.open)) {
      forEach(
        menuL1ItemsElements,
        ({ classList }) => {
          classList.remove(css.classNames.open, css.classNames.close);
        },
      );
      menuWrapElement.classList.remove(css.classNames.open);
      menuItem.querySelector(menuSectionSelector)?.classList.add(css.classNames.close);
      menuItem.querySelector(`.${menuL1ButtonClassName}`).setAttribute('aria-expanded', false);
      mobileMetabarElement.classList.remove(configs.css.classNames.hide);
      b2bB2cToggleMobile?.classList.remove(configs.css.classNames.hide);
      if (!isMobileBreakpoint()) headerElement.classList.remove(expandedMenuClassName);
    } else if (menuItem) { // open l1 menu
      forEach(
        menuL1ItemsElements,
        (element) => {
          if (element !== menuItem) {
            element.classList.add(css.classNames.close);
            element.classList.remove(css.classNames.open);
            element.querySelector(menuSectionSelector)?.classList.add(css.classNames.close);
            element.querySelector(`.${menuL1ButtonClassName}`)?.setAttribute('aria-expanded', false);
          }
        },
      );
      menuItem.classList.add(css.classNames.open);
      menuItem.querySelector(menuSectionSelector)?.classList.remove(css.classNames.close);
      menuItem.querySelector(`.${menuL1ButtonClassName}`).setAttribute('aria-expanded', true);
      menuWrapElement.classList.add(css.classNames.open);
      headerElement.classList.add(expandedMenuClassName);
      mobileMetabarElement.classList.add(configs.css.classNames.hide);
      b2bB2cToggleMobile?.classList.add(configs.css.classNames.hide);
      (!isMobileBreakpoint()) && addEventListener(window, 'click', closeMenu);
      if (isUsingKeyboard()) {
        addEventListener(menuItem.querySelector(menuSectionSelector), 'keydown', onMenuSectionKeyDown);
      }
    } else {
      menuWrapElement.classList.remove(css.classNames.open);
      headerElement.classList.remove(expandedMenuClassName);
    }
  };

  const toggleL2Items = (menuItem = null) => {
    // eslint-disable-next-line no-shadow
    const toggleL2menuItemsVisibility = (item, menuItem, force = false) => {
      if (menuItem !== item) item.classList.toggle(css.classNames.close, force);
    };
    // close l2 menu
    if (menuItem?.classList.contains(css.classNames.open)) {
      const l1Item = menuItem.closest(menuL1ItemsSelector);
      l1Item.querySelector(`.${menuL1ButtonClassName}`).classList.remove(css.classNames.close);
      l1Item.querySelector(seeAllL1LinksSelector).classList.remove(css.classNames.close);
      forEach(
        menuWrapElement.querySelectorAll(promoCtaSelector),
        ({ classList }) => classList.remove(css.classNames.close),
      );
      forEach(
        menuItem
          .closest(menuL2ListSelector)
          .querySelectorAll(menuL2ItemsSelector),
        (item) => toggleL2menuItemsVisibility(item, menuItem),
      );
      menuItem.closest(menuL2ItemsSelector).querySelector(menuL3ItemsSelector).classList.add(css.classNames.close);
      menuItem.classList.remove(css.classNames.open);
    } else if (menuItem) { // open l2 menu
      const l1Item = menuItem.closest(menuL1ItemsSelector);
      l1Item.querySelector(`.${menuL1ButtonClassName}`).classList.add(css.classNames.close);
      l1Item.querySelector(seeAllL1LinksSelector).classList.add(css.classNames.close);
      forEach(
        menuWrapElement.querySelectorAll(promoCtaSelector),
        ({ classList }) => classList.add(css.classNames.close),
      );
      forEach(
        menuItem
          .closest(menuL2ListSelector)
          .querySelectorAll(menuL2ItemsSelector),
        (item) => toggleL2menuItemsVisibility(item, menuItem, true),
      );
      menuItem.closest(menuL2ItemsSelector).querySelector(menuL3ItemsSelector).classList.remove(css.classNames.close);
      menuItem.classList.add(css.classNames.open);
    }
  };
  const closeMobileMenu = (e) => {
    if (
      e.code === configs.keyNames.enter
      || e.code === configs.keyNames.space
      || e.code === configs.keyNames.escape
      || e.type === 'click'
    ) {
      clearAllBodyScrollLocks();
      menuWrapElement.classList.add(css.classNames.close);
      headerElement.classList.remove(expandedMenuClassName);
      isUsingKeyboard() && openMobileMenuButtonElement.focus();
      e.preventDefault();
    }
  };

  const openMobileMenu = (e) => {
    if (
      e.code === configs.keyNames.enter
      || e.code === configs.keyNames.space
      || e.type === 'click'
    ) {
      disableBodyScroll(menuWrapElement);
      menuWrapElement.classList.remove(css.classNames.close);
      headerElement.classList.add(expandedMenuClassName);
      isUsingKeyboard() && closeMobileMenuButtonElement.focus();
      e.preventDefault();
    }
  };

  const toggleMenuItemByClick = ({ target }) => {
    if (target.closest(`.${menuL1ButtonClassName}`)) {
      toggleL1Items(target.closest(menuL1ItemsSelector));
    }
    if (target.closest(menuL2ButtonSelector)) {
      toggleL2Items(target.closest(menuL2ItemsSelector));
    }
  };

  const toggleMenuItemByKeyboard = (e) => {
    const menuL1Item = e.target.closest(menuL1ItemsSelector);
    if (e.target.closest(`.${menuL1ButtonClassName}`)) {
      onEscCloseMenu(e);
      if (e.code === configs.keyNames.enter || e.code === configs.keyNames.space) {
        e.preventDefault();
        toggleL1Items(menuL1Item);
        focusFirstLink(menuL1Item);
        menuL1Item?.querySelector(`.${menuL1ButtonClassName}`).setAttribute('aria-expanded', true);
      }
      isMobileBreakpoint() && e.target.closest(mobileMetabarSelector) && onMenuButtonKeyDown(e);
    }
  };

  const loadAndOpenMenuItemOnClick = async (e) => {
    if (e.type === 'keydown') {
      toggleMenuItemByKeyboard(e);
    } else toggleMenuItemByClick(e);
    const loadPromises = map(menuL1ItemsElements, (menuItem) => loadDeferredMenuItems(menuItem, true));
    await Promise.all(loadPromises);
  };

  const logoElement = document.querySelector(logoSELinkSelector);
  const mobileBlockWrap = document.querySelector(mobileBlockWrapSelector);
  if (trimEnd(document.location.href, '/') === trimEnd(logoElement?.href, '/')
    || document.location.href === startChinaLink) {
    logoElement.classList.add(css.classNames.disabled);
    logoElement.setAttribute('tabIndex', '-1');
    mobileBlockWrap.setAttribute('tabIndex', '-1');
  }

  const addListeners = () => {
    addEventListener(closeMobileMenuButtonElement, 'click', closeMobileMenu);
    addEventListener(closeMobileMenuButtonElement, 'keydown', closeMobileMenu);
    addEventListener(openMobileMenuButtonElement, 'click', openMobileMenu);
    addEventListener(openMobileMenuButtonElement, 'keydown', openMobileMenu);
    addEventListener(menuWrapElement, 'click', loadAndOpenMenuItemOnClick);
    addEventListener(menuWrapElement, 'keydown', loadAndOpenMenuItemOnClick);
    addEventListener(window, 'keydown', onEscCloseMenu);
    (!isMobileBreakpoint()) && addEventListener(window, 'keydown', disableArrows);
    addEventListener(window, 'resize', clearAllBodyScrollLocks);
  };

  const overwriteHeaderHeightMobileCSSVariable = () => document
    .documentElement
    .style
    .setProperty(headerHeightMobileCSSVariable, `${mobileHeaderHeightWithGlobalSearch}px`);

  mount((element) => {
    if (
      element.querySelector(`#${globalSearchAppId}`)
      || element.querySelector(menuWrapperSimplifiedSelector)
    ) overwriteHeaderHeightMobileCSSVariable();

    getNodes(element);
    addListeners(element);

    // schedule.complete(
    //   () => {
    //     forEach(menuL1ItemsElements, (menuItem) => {
    //       loadDeferredMenuItems(menuItem, false);
    //     });
    //   }, configs.delays.s10,
    // );

    redefineTargetAttributeOfSustainabilityLink(sustainabilityLink);
  });
});
