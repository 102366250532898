var $class="se2--header-se--mega-menu",$name="HeaderSE/megaMenu",$path="app/components/HeaderSE/partials/megaMenu/index.js",$this={$class,$name,$path,};import {
  closeClassName,
  openClassName,
  menuItemClassName,
  menuNavButtonClassName,
  showClassName,
  level1ButtonPopupSelector,
  level1ItemSelector,
  level2ButtonSelector,
} from 'app/components/HeaderSE/partials/megaMenu/config';

import shell from 'app/modules/shell';

import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';

import { css } from 'configs';

import forEach from 'lodash/forEach';

export default shell.registerPartial($this, ({ addEventListener, mount }) => {
  function showLevel2MenuInMobile(l2btn) {
    l2btn.classList.toggle(css.classNames.hide);
    const { nextElementSibling } = l2btn;
    nextElementSibling?.firstElementChild.classList.toggle(showClassName);
    nextElementSibling?.classList.toggle(closeClassName);
  }

  mount((element) => {
    const menuNavButtons = element.querySelectorAll(`.${menuNavButtonClassName}`);
    const menuItems = element.querySelectorAll(`.${menuItemClassName}`);
    const level2Buttons = element.querySelectorAll(level2ButtonSelector);

    let [activeMenuNavButton] = menuNavButtons;
    let [activeLevel2Item] = menuItems;
    if (!isMobileBreakpoint()) {
      activeMenuNavButton.classList.add(css.classNames.active);
      activeLevel2Item.classList.remove(css.classNames.hide);
    }

    function showOnMobile(buttonElement, index) {
      buttonElement.classList.toggle(openClassName);
      forEach(level2Buttons, showLevel2MenuInMobile);

      forEach(
        menuNavButtons,
        (el) => {
          if (buttonElement !== el) {
            el.classList.toggle(css.classNames.hide);
          }
        },
      );
      buttonElement.closest(level1ItemSelector).querySelector(level1ButtonPopupSelector)
        .classList.toggle(closeClassName, buttonElement.classList.contains(openClassName));

      element.classList.toggle(css.classNames.expanded);
      menuItems[index].classList.toggle(css.classNames.hide);
    }

    function showOnDesktop(buttonElement, index) {
      activeMenuNavButton.classList.remove(css.classNames.active);
      activeLevel2Item.classList.add(css.classNames.hide);
      buttonElement.classList.add(css.classNames.active);
      menuItems[index].classList.remove(css.classNames.hide);
      activeMenuNavButton = buttonElement;
      activeLevel2Item = menuItems[index];
    }

    forEach(
      menuNavButtons,
      (button, i) => {
        isMobileBreakpoint()
          ? addEventListener(button, 'click', () => showOnMobile(button, i))
          : addEventListener(button, 'mouseover', () => showOnDesktop(button, i));
      },
    );

    function onWindowResize() {
      activeMenuNavButton?.classList.toggle(css.classNames.active, !isMobileBreakpoint());
      activeLevel2Item?.classList.toggle(css.classNames.hide, isMobileBreakpoint());

      const openedBtnIdx = Array.from(menuNavButtons).findIndex((btn) => btn.classList.contains(openClassName));

      if (isMobileBreakpoint()) {
        activeMenuNavButton.classList.add(css.classNames.active);
        menuNavButtons[openedBtnIdx]?.classList.remove(css.classNames.active);
      } else if (menuNavButtons[openedBtnIdx]) {
        forEach(level2Buttons, showLevel2MenuInMobile);
        forEach(menuNavButtons, ({ classList }) => classList.remove(css.classNames.hide));

        menuNavButtons[openedBtnIdx].closest(level1ItemSelector).querySelector(level1ButtonPopupSelector)
          .classList.remove(closeClassName);

        element.classList.remove(css.classNames.expanded);
        menuItems[openedBtnIdx]?.classList.toggle(css.classNames.hide);
        menuNavButtons[openedBtnIdx].classList.remove(openClassName);
      }
    }

    addEventListener(window, 'resize', onWindowResize);
  });
});
